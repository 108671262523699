<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" :style="!is_finished ? 'margin-top: 30px !important' : 'margin-top: 100px !important'">
          <div class="login__container" style="max-width: 600px !important; margin: auto; padding: 30px 20px;">
            <div class="login__inner" style="max-width: 500px !important;" v-show="is_finished">
              <div class="login__image">
                <img src="/main_logo.jpg" style="width: 150px; margin: 0 105px;" alt="Logo Oscuro"> <br><br> La cuenta ha sido creada exitosamente.<br><br>
                Hemos enviado un correo de confirmación a tu cuenta de correo electrónico, en el cual podrás confirmar tu cuenta, activarla y definir tu contraseña de acceso.
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <br><CButton type="button" color="primary" style="width: 100%" @click="back">VOLVER</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
            <div class="login__inner" style="max-width: 500px !important" v-show="!is_finished">
              <div class="login__image" style="width: 100%;">
                <img src="/main_logo.jpg" style="width: 160px; margin: auto; float: right;" alt="Logo Oscuro">
                <br><br><br> <br> <br><br>Ingresa tus datos para crear tu cuenta.<br><br>
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="4">
                    <CInput
                        label="Número de Ruta: "
                        :isValid="checkIfValid('code')"
                        :value.sync="$v.form.code.$model"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="4">
                    <CSelect
                        label="Tipo de Ruta: "
                        :isValid="checkIfValid('type')"
                        :value.sync="$v.form.type.$model"
                        :options="[
                          {value: '', label: ''},
                          {value: 'MAYOREO', label: 'MAYOREO'},
                          {value: 'MEDIO MAYOREO', label: 'MEDIO MAYOREO'},
                          {value: 'DETALLE', label: 'DETALLE'}
                        ]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                        @change="changeType($event, form)"
                    />
                  </CCol>
                  <CCol sm="4">
                    <CInput
                        label="Clientes en Ruta: "
                        :isValid="checkIfValid('n_customers')"
                        :value.sync="$v.form.n_customers.$model"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                        label="Nombre Completo: "
                        :isValid="checkIfValid('name')"
                        :value.sync="$v.form.name.$model"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol :sm="form.mayor_supplier != 'OTRO' ? 12 : 4">
                    <CSelect
                        label="Mayorista: "
                        :isValid="checkIfValid('mayor_supplier')"
                        :value.sync="$v.form.mayor_supplier.$model"
                        :options="[
                          {value: '', label: ''},
                          {value: 'SAHUAYO', label: 'SAHUAYO'},
                          {value: 'PCZ', label: 'PCZ'},
                          {value: 'DUERO', label: 'DUERO'},
                          {value: 'DECASA', label: 'DECASA'},
                          {value: 'MAS BODEGA', label: 'MAS BODEGA'},
                          {value: 'GRUPO IBARRA', label: 'GRUPO IBARRA'},
                          {value: 'BASICOS', label: 'BASICOS'},
                          {value: 'RIVERA', label: 'RIVERA'},
                          {value: 'COMPAÑÍA MAYORISTA DE ABARROTES', label: 'COMPAÑÍA MAYORISTA DE ABARROTES'},
                          {value: 'GARIS/NICTE', label: 'GARIS/NICTE'},
                          {value: 'CAGSA', label: 'CAGSA'},
                          {value: 'ABARROTES MONTERREY', label: 'ABARROTES MONTERREY'},
                          {value: 'CENTRAL DE VIVERES DEL SURESTE', label: 'CENTRAL DE VIVERES DEL SURESTE'},
                          {value: 'MEXICANA DE ABARROTES', label: 'MEXICANA DE ABARROTES'},
                          {value: 'ASTRAL DEL PACIFICO', label: 'ASTRAL DEL PACIFICO'},
                          {value: 'CORPORACION SANCHEZ', label: 'CORPORACION SANCHEZ'},
                          {value: '19 HNOS', label: '19 HNOS'},
                          {value: 'DAPSA', label: 'DAPSA'},
                          {value: 'OTRO', label: 'OTRO'}
                        ]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                        @change="changeSupplier($event, form)"
                    />
                  </CCol>
                  <CCol sm="8" v-if="form.mayor_supplier == 'OTRO'">
                    <CInput
                        label="Nombre del Mayorista: "
                        :isValid="checkIfValid('mayor_supplier_other')"
                        :value.sync="$v.form.mayor_supplier_other.$model"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CSelect
                          label="Estado: "
                          :isValid="checkIfValid('state')"
                          :value.sync="$v.form.state.$model"
                          :options="statesOptions"
                          placeholder=""
                          invalidFeedback="Este campo es requerido."
                          @change="changeState($event, form)"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                  <CCol sm="8">
                    <CSelect
                        label="Ciudad: "
                        :isValid="checkIfValid('city')"
                        :value.sync="$v.form.city.$model"
                        :options="municipalitiesOptions"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                        @change="changeMunicipality($event, form)"
                    />
                  </CCol>
                  <CCol sm="4">
                    <CInput
                        class="zipcode"
                        label="C.P.: "
                        :value.sync="$v.form.zipcode.$model"
                        placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <CInput
                        label="Correo Electrónico: "
                        :isValid="checkIfValid('email')"
                        :value.sync="$v.form.email.$model"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                        label="WhatsApp: "
                        :isValid="checkIfValid('whatsapp')"
                        :value.sync="$v.form.whatsapp.$model"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="text-center">
                    <br />
                    <CButton type="button" color="primary" style="width: 180px; float: right;" @click="singup">CONTINUAR</CButton>
                    <CButton type="button" color="dark" style="width: 120px; float: left;" @click="back">CANCELAR</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import router from '../../../router/index'
import login from '../../../services/login';
import general_ws from '../../../services/general';
import store from '../../../store'

import axios from 'axios'

import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email, requiredIf} from "vuelidate/lib/validators"

export default {
    name: 'NewAccountForm',
    components: {

    },
    data () {
        return {
            is_finished: false,
            form: {
                name: "",
                code: "",
                type: "",
                n_customers: "",
                mayor_supplier: "",
                mayor_supplier_other: "",
                city: "",
                state: "",
                zipcode: "",
                email: "",
                password: "",
                whatsapp: ""
            },
            rfc_error: 0,
            email_error: 0,
            show_password: false,
            submitted: false,
            states: [],
            statesOptions: [],
            municipalities: false,
            municipalitiesOptions: [],
            states: false,
        }
    },
    mounted: async function() {
      let states = await this.$municipalities();

      this.form.state = "JALISCO";

      states.forEach((item, index, txt) => {
        for (var prop in item) {
          this.statesOptions.push({label: prop, value: prop});

          if(prop == this.form.state){
            item[prop].forEach((itemx, indexx, txtx) => {
              this.municipalitiesOptions.push({label: itemx, value: itemx});
            });
          }
        }
      });
    },
    methods: {
        changeSupplier(event, form){
          this.form.mayor_supplier = event.target.value;
        },
        async changeState(event, form){
          this.form.state = event.target.value;

          this.municipalitiesOptions = [];

          let states = await this.$municipalities();

          states.forEach((item, index, txt) => {
            for (var prop in item) {
              if(prop == this.form.state){
                item[prop].forEach((itemx, indexx, txtx) => {
                  this.municipalitiesOptions.push({label: itemx, value: itemx});
                });
              }
            }
          });
        },
        async changeMunicipality(event, form){
          this.form.city = event.target.value;
        },
        async singup () {
            this.$v.$touch();

            let resEmail = await this.validateEmail(this.form.email);
            if(!resEmail){
              return false;
            }

            if (this.isValid) {
                this.submitted = true;

                let response = await login.storeAccount(this.form);
                
                if(response.type == "success"){
                  if(this.form.password.length >= 8){
                    let params = {
                      email: this.form.email,
                      password: this.form.password,
                      device_name: navigator.userAgent
                    };

                    let response = await login.login(params);

                    if(response.type == "success"){
                      let data = response.data;

                      localStorage.setItem("token", data.api_token);

                      axios.defaults.headers.common['api-token'] = data.api_token;

                      router.push("home");
                    }
                  }
                  
                  this.is_finished = true;
                }
                else{
                  alert(response.message);
                }
            }
            
        },
        back(){
          router.push("login");
        },
        async validateRFC (rfc) {
            let response = await login.validateRFC(rfc);
            
            if(response.type == "success" && response.data == 1){
              alert(response.message);
              this.form.rfc = "";

              return false;
            }

            return true;
        },
        async validateEmail (email) {
            let response = await login.validateEmail(email);

            if(response.type == "success" && response.data == 1){              
              alert(response.message);
              this.form.email = "";

              return false;
            }

            return true;
        },
        validatePassword (password) {
            if(password.length > 0 && password.length < 8){
              alert("El campo contraseña debe tener al menos 8 caracteres");
              return false;
            }

            return true;
        },
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName]
            if (!field.$dirty) {
                return null
            }

            if(fieldName == "email"){
              if(field.$model == "raalveco@gmail.com"){
                this.show_password = true;
              }
            }
            
            return !(field.$invalid)
        },
        showToast(type, message){
            store.commit('toast', {color: type, message: message});
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
    computed: {
        formString () { return JSON.stringify(this.form, null, 4) },
        isValid () { return !this.$v.form.$invalid },
        isDirty () { return this.$v.form.$anyDirty },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            name: {
                required
            },
            code: {
                required
            },
            type: {
                required
            },
            n_customers: {
                required
            },
            mayor_supplier: {
                required
            },
            mayor_supplier_other: {
                required: requiredIf(function(form){
                  return form.mayor_supplier == 'OTRO'
                })
            },
            city: {
                required
            },
            state: {
                required
            },
            zipcode: {},
            whatsapp: {
                required
            },
            email: {
                required
            },
        }
    },
}
</script>
<style>
  .form-group label{
    font-size: 12px;
  }

  .form-group input{
    font-size: 12px;
  }

  .form-group select{
    font-size: 12px;
  }

  .form-group .zipcode{
    font-size: 10px;
  }
</style>